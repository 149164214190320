import React from 'react';
import Container from "../../Container/Container";
import Cards from "./Cards.jsx";
import "./Product.css";

const Product = () => {

    return (
          <Container>
        

        <div>
            <Cards/>
        </div>


          </Container>

    );
};


export default Product;
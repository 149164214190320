import React from 'react';
import './Cards.css';
// import imag1 from "../../Assets/website-10\ 4\ \(6\).jpg";
// import imag2 from "../../Assets/website-11\ 2.jpg";
import c1 from '../../Assets/client/dm-khi.jpg'
// import imag3 from "../../Assets/website-12\ 2.jpg";
import simeens from "../../Assets/client/simeens.jpg";
import mcb from "../../Assets/client/mcb.jpg";
import king from "../../Assets/client/kingcret.jpg";
import govpak from "../../Assets/client/govt-pak.jpg";
import nagaroa from "../../Assets/client/nagaria.jpg";

import expnews from "../../Assets/client/express-news.jpg";
import maqco from "../../Assets/client/maqbool-co.jpg";
import prodigy from "../../Assets/client/prodigy.jpg";

import skhanam from "../../Assets/client/s-khanam.jpg";
import dolmanfur from "../../Assets/client/dolman-fur.jpg";
import shazresi from "../../Assets/client/shaz-residency.jpg";

import akhtar from "../../Assets/client/akhtar.jpg";
import tribun from "../../Assets/client/tribun.jpg";
import tripack from "../../Assets/client/tri-pack.jpg";

import ox from "../../Assets/client/ox.jpg";
import rehmaniprp from "../../Assets/client/rehmani-prp.jpg";
import dolmanpro from "../../Assets/client/dolman-pro.jpg";

import packaginggrp from "../../Assets/client/packages-grp.jpg";
import bizintel from "../../Assets/client/bizintel.jpg";
import portkey from "../../Assets/client/portkey.jpg";



import ludh from "../../Assets/client/ludh.jpg";
import rlf from "../../Assets/client/rlf.jpg";
import bein from "../../Assets/client/beinc.jpg";

import emaar from "../../Assets/client/emmar.jpg";
import pakcable from "../../Assets/client/pakcable.jpg";
import dow from "../../Assets/client/dow.jpg";

// import imag4 from "../../Assets/website-13\ 2.jpg";
// import imag5 from "../../Assets/website-14\ 2.jpg";
// import imag6 from "../../Assets/website-15\ 2.jpg";
// import imag7 from "../../Assets/website-16\ 2.jpg";
// import imag8 from "../../Assets/website-17\ 2.jpg";
// import imag9 from "../../Assets/website-19\ 1.jpg";
// import imag10 from "../../Assets/website-20\ 1.jpg";
// import imag11 from "../../Assets/website-21\ 1.jpg";
// import imag12 from "../../Assets/website-22\ 1.jpg";
// import imag13 from "../../Assets/website-23\ 1.jpg";
// import imag14 from "../../Assets/website-24\ 1.jpg";
// import imag15 from "../../Assets/scaffolding-30\ 1.jpg";
// import imag16 from "../../Assets/website-25\ 1.jpg";
// import imag17 from "../../Assets/website-26\ 2.jpg";
// import imag18 from "../../Assets/website-18\ 2.jpg";
// import imag19 from "../../Assets/website-27\ 1.png";
// import imag20 from "../../Assets/website-28\ 1.jpg";
// import imag21 from "../../Assets/warehouse-10\ 1.jpg";

function Cards() {

  return (
    <>
      <div className="card-main">
        <div className="container4">
          <h1 className="cardhead">Clients</h1>
          <div className="container-5">
            <div className="row">
              <div className="column4">
                <div className="box1">
                  <img src={c1} />
                  {/* <div className="bord"></div> */}
                  <h3 className="heading1"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box2">
                  <img src={simeens} />
                  {/* <div className="bord1"></div> */}
                  <h3 className="heading2"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box3">
                  <img src={mcb} />
                  {/* <div className="bord2"></div> */}
                  <h3 className="heading3"></h3>
                </div>
              </div>
            </div>


{/* row 2 */}

<div className="row">
              <div className="column4">
                <div className="box1">
                  <img src={king} />
                  {/* <div className="bord"></div> */}
                  <h3 className="heading1"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box2">
                  <img src={govpak} />
                  {/* <div className="bord1"></div> */}
                  <h3 className="heading2"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box3">
                  <img src={nagaroa} />
                  {/* <div className="bord2"></div> */}
                  <h3 className="heading3"></h3>
                </div>
              </div>
            </div>


{/* row 3 */}

<div className="row">
              <div className="column4">
                <div className="box1">
                  <img src={maqco} />
                  {/* <div className="bord"></div> */}
                  <h3 className="heading1"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box2">
                  <img src={expnews} />
                  {/* <div className="bord1"></div> */}
                  <h3 className="heading2"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box3">
                  <img src={prodigy} />
                  {/* <div className="bord2"></div> */}
                  <h3 className="heading3"></h3>
                </div>
              </div>
            </div>

{/* row 4 */}

<div className="row">
              <div className="column4">
                <div className="box1">
                  <img src={skhanam} />
                  {/* <div className="bord"></div> */}
                  <h3 className="heading1"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box2">
                  <img src={dolmanfur} />
                  {/* <div className="bord1"></div> */}
                  <h3 className="heading2"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box3">
                  <img src={shazresi} />
                  {/* <div className="bord2"></div> */}
                  <h3 className="heading3"></h3>
                </div>
              </div>
            </div>


{/* row 5 */}

<div className="row">
              <div className="column4">
                <div className="box1">
                  <img src={akhtar} />
                  {/* <div className="bord"></div> */}
                  <h3 className="heading1"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box2">
                  <img src={tribun} />
                  {/* <div className="bord1"></div> */}
                  <h3 className="heading2"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box3">
                  <img src={tripack} />
                  {/* <div className="bord2"></div> */}
                  <h3 className="heading3"></h3>
                </div>
              </div>
            </div>


{/* row 6 */}

<div className="row">
              <div className="column4">
                <div className="box1">
                  <img src={ox} />
                  {/* <div className="bord"></div> */}
                  <h3 className="heading1"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box2">
                  <img src={rehmaniprp} />
                  {/* <div className="bord1"></div> */}
                  <h3 className="heading2"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box3">
                  <img src={dolmanpro} />
                  {/* <div className="bord2"></div> */}
                  <h3 className="heading3"></h3>
                </div>
              </div>
            </div>


{/* row 7 */}

<div className="row">
              <div className="column4">
                <div className="box1">
                  <img src={packaginggrp} />
                  {/* <div className="bord"></div> */}
                  <h3 className="heading1"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box2">
                  <img src={bizintel} />
                  {/* <div className="bord1"></div> */}
                  <h3 className="heading2"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box3">
                  <img src={portkey} />
                  {/* <div className="bord2"></div> */}
                  <h3 className="heading3"></h3>
                </div>
              </div>
            </div>

{/* row 8 */}

<div className="row">
              <div className="column4">
                <div className="box1">
                  <img src={ludh} />
                  {/* <div className="bord"></div> */}
                  <h3 className="heading1"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box2">
                  <img src={rlf} />
                  {/* <div className="bord1"></div> */}
                  <h3 className="heading2"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box3">
                  <img src={bein} />
                  {/* <div className="bord2"></div> */}
                  <h3 className="heading3"></h3>
                </div>
              </div>
            </div>


{/* row 8 */}

<div className="row">
              <div className="column4">
                <div className="box1">
                  <img src={emaar} />
                  {/* <div className="bord"></div> */}
                  <h3 className="heading1"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box2">
                  <img src={dow} />
                  {/* <div className="bord1"></div> */}
                  <h3 className="heading2"></h3>
                </div>
              </div>

              <div className="column4">
                <div className="box3">
                  <img src={pakcable} />
                  {/* <div className="bord2"></div> */}
                  <h3 className="heading3"></h3>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>


    </>
  )
}

export default Cards;
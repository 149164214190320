import React from "react";
import Container from "../../Container/Container";
import "./Contact.css";
import Form from "./Form.jsx";

const Contact = () => {

  return (
        <Container>


          <div>
              <Form/>
          </div>
        </Container>
  );
};




export default Contact;
import React from 'react';
import './Cards.css';
import img from "../../Assets/website-19\ 1.jpg";
import img1 from "../../Assets/warehouse-10\ 1.jpg";
import img2 from "../../Assets/scaffolding-30\ 1.jpg";
import img3 from "../../Assets/website-26\ 2.jpg";

function Cards() {

 return (
<div className="container2">
<div className="row" className="core-features">
  <div className="column">
    <div className="Card1"> 
    {/* <img src={img}/>
    <div className="border"></div> */}
      <h3 className="heading1">HVAC</h3> 
      <p  className='j-text'>
      {/* <ul>
          <li>Designing</li>
          <li>Cutting</li>
          <li>Bending</li>
          <li>Forming</li>
          <li>Pressing</li>
          <li>Punching</li>
          <li>Welding</li>
          <li>Assembling</li>
          <li>Finishing</li>
          <li>Installing</li>
      </ul> */}
      We offer expert Heating, Ventilation, and Air Conditioning solutions for residential, commercial, and industrial sector. From system installation to regular Operation and Maintenance, we ensure quality workmanship for the installation & maintenance of HVAC systems. 
      </p>
    </div>
  </div>

  <div className="column">
    <div className="Card1"> 
    {/* <img src={img1}/>
    <div className="border"></div> */}
      <h3 className="heading1">Firefighting</h3> 
      <p  className='j-text'>
      We specialize in comprehensive firefighting system installations, ensuring the highest standards of safety and compliance. Our offerings include the supply, installation and maintenance of fire protection and suppression systems. 
      </p>
    </div>
  </div>

  
  <div className="column">
    <div className="Card1"> 
    {/* <img src={img2}/>
    <div className="border"></div> */}
      <h3 className="heading1">Elevators</h3> 
      <p  className='j-text'>
      Our elevator division includes the supply, installation and maintenance of elevators and escalators. We prioritize safety, reliability and performance, offering cutting-edge solutions that enhance vertical transportation in buildings of all types.
      </p>
    </div>
  </div>

  
  <div className="column">
    <div className="Card1"> 
    {/* <img src={img3}/>
    <div className="border"></div> */}
      <h3 className="heading1">Our Commitment</h3> 
      <p className='j-text'>
      We are committed to excellence, innovation, and customer satisfaction. Our team works diligently to stay ahead of industry trends and technological advancements, ensuring that we provide the best possible service and support to our clients. We believe in building long-term relationships based on trust, integrity, and a shared commitment to quality and safety.
      </p>
    </div>
  </div>
  </div>
  </div>

 )
}

export default Cards;
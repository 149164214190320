import React from 'react';
import './Para.css';


function Para () {
    return (
<div className="container">
<div className="hse-policy">

     {/* <div className="h1">
         <h5></h5>
     </div> */}
     <br/> <br/><br/><br/><br/><br/>
      <div className="p1">
      <p>“Habibullah” is a third-generation family business with experience of over 40+ years in the market. Habibullah Engineering (Pvt) Ltd. (HEPL) offers complete Mechanical, Firefighting, Elevators (Vertical transport) and Heating Ventilation and Airconditioning (HVAC) engineered custom solutions. We provide initial planning to final construction for a variety of projects with engineering solutions as per client’s requirements.<br/><br/>


      HEPL has the ability to complete projects on a comprehensive turnkey or partial basis. We take pride in offering customized engineering solutions while adding value for our clients. We deliver high quality products through our qualified trained staff. HEPL has industrial arrangements focused on the manufacturing and fabrication of custom engineered products.<br/><br/>


      We use the finest quality products as they pass through a stringent QA/ QC process under the supervision of well trained and capable experts. At HEPL, we are committed to providing clients with best engineered solution within their budget and time frame.<br/><br/>

      HEPL also represents NBSL Elevator Manufacturer in Pakistan. NBSL is one of the largest elevator components manufacturers in the world. It is the OEM of top European and Japanese brands like Kone, Otis, Thyssenkrupp, Hitachi, Toshiba, Mitsubishi and many more. Since last 30 years, it was manufacturing for these brands only and it is still its main business. It also exports complete elevator units. The client gets a very high-quality elevator on very competitive price just with a different brand name. <br/><br/>

      We are an ISO 9001-2015 certified company by a European Agency.</p>
      </div>
</div>
</div>
    )
}




export default Para;
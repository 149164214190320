import React from 'react';
import Container from "../../Container/Container";
import "./Machinery.css";
import Col from "./Col.jsx";


const Machine = () => {
    return (
        <Container>

      <div>
          <Col/>
      </div>
</Container>
    );
};



export default Machine;